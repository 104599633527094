<template>
  <div id="hrDashboard">
    <!-- Annoucements -->
    <annoucementsDashbordComponent></annoucementsDashbordComponent>
    <!-- Annoucements -->
    <!-- Fast Statistics  -->
    <v-row class="statistics mt-4">
      <v-col class="text-right" v-if="is_imployee" cols="12" sm="12">
        <v-btn class="modal-btn-save" to="/leaverequest">{{
          $t("Requests")
        }}</v-btn>
      </v-col>
      <!-- Leave Statistics -->
      <v-col cols="12" sm="6" md="3" class="item item1">
        <v-sheet width="100%" height="130px" class="mx-auto pa-4">
          <v-row>
            <v-col class="text-center">
              <p class="num mb-0">
                {{ statistticsObject.request_count }}
              </p>
              <p class="text grey--text">{{ $t("Leave Request") }}</p>
            </v-col>
            <v-col class="text-center">
              <a
                href="/VacationRequests?vacationrequest=true&filter=pending"
                target="_blanck"
                class="link-open"
                style="width: 100%; display: block"
              >
                <p class="grey--text mb-0 text">
                  {{ $t("Pending") }} {{ statistticsObject.pending_count }}
                </p>
              </a>
              <a
                href="/VacationRequests?vacationrequest=true&filter=declined"
                target="_blanck"
                class="link-open"
                style="width: 100%; display: block"
              >
                <p class="grey--text mb-0 text">
                  {{ $t("Rejected") }} {{ statistticsObject.rejectd_count }}
                </p>
              </a>
              <a
                href="/VacationRequests?vacationrequest=true&filter=approved"
                target="_blanck"
                class="link-open"
                style="width: 100%; display: block"
              >
                <p class="grey--text mb-0 text">
                  {{ $t("Confirmed") }} {{ statistticsObject.confirmed_count }}
                </p>
              </a>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <!-- Contract Statistics -->
      <v-col cols="12" sm="6" md="3" class="item item2">
        <v-sheet width="100%" height="130px" class="mx-auto pa-4">
          <v-row>
            <v-col class="text-center">
              <p class="num mb-0">{{ statistticsObject.contract_count }}</p>
              <p class="text grey--text">{{ $t("Contracts") }}</p>
            </v-col>
            <v-col class="text-center">
              <a
                href="/employees?status=valid"
                target="_blanck"
                class="link-open"
                style="width: 100%; display: block"
              >
                <p class="grey--text mb-0 text">
                  {{ $t("Valid") }} {{ statistticsObject.contract_valid_count }}
                </p>
              </a>
              <a
                href="/employees?status=expire_soon"
                target="_blanck"
                class="link-open"
                style="width: 100%; display: block"
              >
                <p class="grey--text mb-0 text">
                  {{ $t("Ending Soon") }}
                  {{ statistticsObject.contract_ending_soon_count }}
                </p>
              </a>
              <a
                href="/employees?status=expired"
                target="_blanck"
                class="link-open"
                style="width: 100%; display: block"
              >
                <p class="grey--text mb-0 text">
                  {{ $t("Archived") }} {{ statistticsObject.contract_archived }}
                </p>
              </a>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <!-- Employee Probation -->
      <v-col cols="12" sm="6" md="3" class="item item3">
        <v-sheet width="100%" height="130px" class="mx-auto pa-4">
          <v-row>
            <v-col class="text-center">
              <p class="num mb-0">{{ statistticsObject.probation_count }}</p>
              <p class="text grey--text">{{ $t("Employees On Probation") }}</p>
            </v-col>
            <v-col class="text-center">
              <a
                href="/employees?statusProbation=valid"
                target="_blanck"
                class="link-open"
                style="width: 100%; display: block"
              >
                <p class="grey--text mb-0 text">
                  {{ $t("Ongoing") }} {{ statistticsObject.probation_onging }}
                </p>
              </a>

              <a
                href="/employees?status=valid"
                target="_blanck"
                class="link-open"
                style="width: 100%; display: block"
              >
                <p class="grey--text mb-0 text">
                  {{ $t("Contracted") }}
                  {{ statistticsObject.probation_contracted }}
                </p>
              </a>
              <a
                href="/employees?status=expired"
                target="_blanck"
                class="link-open"
                style="width: 100%; display: block"
              >
                <p class="grey--text mb-0 text">
                  {{ $t("Terminated") }}
                  {{ statistticsObject.probation_terminated }}
                </p>
              </a>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <!-- Unfinished Salareies -->
      <v-col cols="12" sm="6" md="3" class="item item4">
        <v-sheet width="100%" height="130px" class="mx-auto pa-4">
          <v-row>
            <v-col class="text-center">
              <p class="num mb-0">{{ count_unfinished_salaries }}</p>
              <p class="text grey--text">{{ $t("Unfinished Salareies") }}</p>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" md="4">
        <doughnutChart></doughnutChart>
      </v-col>

      <v-col cols="12" sm="6" md="4" class="">
        <hrSalaries></hrSalaries>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="salaries-total">
        <hrTotalSalaries></hrTotalSalaries>

        <v-card max-width="100%" class="mt-4 px-4 py-2 second-card">
          <h3 class="main-color">{{ $t("Quick Navigation") }}</h3>
          <v-card-text>
            <div class="item d-flex justify-space-between">
              <router-link
                to="/VacationRequests?vacationrequest=true"
                class="main-color mb-2"
              >
                <v-icon class="main-color">flight</v-icon>
                {{ $t("Leave Request") }}
              </router-link>

              <p class="grey--text">
                {{ LeaveRequestsCount }} {{ $t("Requests") }}
              </p>
            </div>
            <div class="item d-flex justify-space-between">
              <router-link
                to="/VacationRequests?permissionRequest=true"
                class="main-color mb-2"
              >
                <v-icon class="main-color">flight_takeoff</v-icon>
                {{ $t("Permission Requests") }}
              </router-link>

              <p class="grey--text">
                {{ permissionRequestsCount }} {{ $t("Requests") }}
              </p>
            </div>
            <div class="item d-flex justify-space-between">
              <router-link to="/allEmployees/salaries" class="main-color mb-2">
                <v-icon class="main-color">attach_money</v-icon>
                {{ $t("Payroll") }}
              </router-link>
            </div>

            <div class="item d-flex justify-space-between">
              <router-link to="/MasterAttendance" class="main-color mb-2">
                <v-icon class="main-color">spellcheck</v-icon>
                {{ $t("Attendance") }}
              </router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="12">
        <LeaveRequests></LeaveRequests>
      </v-col>
    </v-row>
    <!-- Leave Statistics Chart -->
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-card max-width="100%" class="px-4 py-2 leave-stats">
          <v-row>
            <v-col cols="12" class="py-0">
              <h2 class="main-color card-title">
                {{ $t("Leave Statistics") }}
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <!--job title -->
                <v-col cols="12" md="3">
                  <label>{{ $t("Job title") }} </label>
                  <v-autocomplete
                    :items="jobItems"
                    item-text="name"
                    item-value="id"
                    v-model="filters.job_title"
                    multiple
                    solo
                  >
                  </v-autocomplete>
                </v-col>
                <!--Vacation Reason  -->
                <v-col cols="12" md="3">
                  <label>{{ $t("Vacation Reason") }} </label>
                  <v-autocomplete
                    :items="vacationsItems"
                    v-model="filters.reasons"
                    multiple
                    item-text="name"
                    item-value="id"
                    solo
                  >
                  </v-autocomplete>
                </v-col>
                <!-- Month -->
                <v-col cols="12" md="3">
                  <label>{{ $t("Month") }} </label>
                  <v-select
                    :items="months"
                    v-model="filters.month"
                    item-text="month"
                    item-value="id"
                    class="mr-4"
                    dense
                    solo
                  ></v-select>
                </v-col>
                <!-- years -->
                <v-col cols="12" md="3">
                  <label>{{ $t("Year") }} </label>
                  <v-select
                    :items="years"
                    v-model="filters.year"
                    item-text="year"
                    item-value="year"
                    class="mr-4"
                    dense
                    solo
                  ></v-select>
                </v-col>
              </v-row>

              <div class="d-flex"></div>
            </v-col>
          </v-row>
          <v-row>
            <canvas id="leave-chart" dir="rtl" height="350"></canvas>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="8">
        <hrAllowance></hrAllowance>
      </v-col>
      <v-col cols="12" sm="12" md="4" class="">
        <EmployeesOnHolidays></EmployeesOnHolidays>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <EmployeeTable></EmployeeTable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

import Chart from "chart.js";
import doughnutChart from "../../components/doughnutChart";
import LeaveRequests from "../../components/dashboards/hrLeaveRequests";
import hrAllowance from "../../components/dashboards/hrAllowance";
import hrSalaries from "../../components/dashboards/hrSalaries";
import hrTotalSalaries from "../../components/dashboards/hrTotalSalaries";
import EmployeesOnHolidays from "../../components/dashboards/hrEmployeesOnHoliday";
import EmployeeTable from "../../components/dashboards/EmployeeTable";
import annoucementsDashbordComponent from "../../components/annoucementsDashbordComponent";
export default {
  name: "HrDashboard",
  components: {
    doughnutChart,
    LeaveRequests,
    EmployeesOnHolidays,
    hrAllowance,
    hrSalaries,
    hrTotalSalaries,
    EmployeeTable,
    annoucementsDashbordComponent,
  },
  data() {
    return {
      count_unfinished_salaries: 0,
      nationalities: [
        this.$i18n.t("saudi"),
        this.$i18n.t("egypt"),
        this.$i18n.t("asd"),
      ],
      is_imployee: false,
      jobItems: [],
      vacationsItems: [],
      years: [],
      months: [],
      // teachersData: [{ x: "Hak", y: 2 }],
      // hrsData: [
      //   { x: "Hak", y: 1 },
      //   { x: "مرضي", y: 1 },
      //   { x: "عرضي", y: 2 },
      // ],

      /* employees table */
      employeesheaders: [
        {
          text: this.$i18n.t("Name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: this.$i18n.t("jobTitle"), value: "jobTitle" },
        { text: this.$i18n.t("Nationality"), value: "Nationality" },
        { text: this.$i18n.t("IdExpiration"), value: "IdExpiration" },
        { text: this.$i18n.t("ExpirationDate"), value: "ExpirationDate" },
        {
          text: this.$i18n.t("contractStatus"),
          value: "contractStatus",
          width: "50%",
          class: "fixed-width",
        },
        { text: this.$i18n.t("Actions"), value: "actions" },
      ],
      employeesItems: [
        {
          name: "mahmoud kamal",
          jobTitle: "developer",
          Nationality: "egypt",
          IdExpiration: "2522",
          ExpirationDate: "22/11/2020",
          contractStatus: "true",
          actions: "",
        },
      ],
      filters: {
        reasons: [],
        job_title: [],
        month: "",
        year: "",
      },
      LeaveRequestsCount: "",
      permissionRequestsCount: "",
      statistticsObject: {
        contract_count: "",
        contract_valid_count: "",
        contract_ending_soon_count: "",
        contract_archived: "",
        probation_count: "",
        probation_onging: "",
        probation_contracted: "",
        probation_terminated: "",
        request_count: "",
        pending_count: "",
        rejectd_count: "",
        confirmed_count: "",
      },
      datasets: [],
      reasonschart: [],
    };
  },
  watch: {
    filters: {
      handler() {
        this.getLeavesStatisticsChart();
      },
      deep: true,
    },
  },
  methods: {
    createBarChart() {
      /*-------------------------------------
          bar Chart 
      -------------------------------------*/
      const ratingChart = document
        .getElementById("leave-chart")
        .getContext("2d");

      if (this.chart) {
        this.chart.destroy();
      }

      // eslint-disable-next-line no-unused-vars
      this.chart = new Chart(ratingChart, {
        type: "bar", // "bar", "horizontalBar", "doughnut", "line", "pie", "radar", "polarArea"
        data: {
          labels: this.reasonschart, //Reasons
          backgroundColor: [],
          datasets: this.datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          // hover: { mode: null },

          scales: {
            xAxes: [
              {
                ticks: {
                  padding: 20,
                  fontFamily: "FontAwesome",
                  autoSkip: false,
                  // maxRotation: 90,
                  // minRotation: 90,
                  fontColor: "#FFB400",
                  reverse: this.currentAppLocale == "en" ? false : true,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  // eslint-disable-next-line no-unused-vars
                  userCallback: function (label, index, labels) {
                    // when the floored value is the same as the value we have a whole number
                    if (Math.floor(label) === label) {
                      return label;
                    }
                  },
                },
                position: this.currentAppLocale == "en" ? "left" : "right",
              },
            ],
          },
          legend: {
            display: true,
          },
          tooltips: {
            enabled: true,
            titleFontFamily: "FontAwesome",
            bodyFontFamily: "FontAwesome",
          },
        },
      });
    },
    getRequestLeaveCount() {
      axios
        .get(this.getApiUrl + "/requestleave/requestsCount", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.LeaveRequestsCount = response.data.data.leaves_count;
          this.permissionRequestsCount = response.data.data.permission_count;
        });
    },

    getLeavesStatisticsFIlterItems() {
      axios
        .get(this.getApiUrl + "/requestleave/getLeavesStatisticsFIlterItems", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.jobItems = response.data.data.jobs;
          this.vacationsItems = response.data.data.vacations_reasons;
        });
    },
    getLeavesStatisticsChart() {
      axios
        .get(this.getApiUrl + "/requestleave/getLeavesStatisticsChart", {
          params: {
            filter: this.filters,
          },
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.datasets = response.data.data.data;
          this.reasonschart = response.data.data.reasons;
          this.createBarChart();
        });
    },
    getYears() {
      axios
        .get(this.getApiUrl + "/hrConfigration/getYears", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.years = response.data.data;
        });
    },

    getMonths() {
      axios
        .get(this.getApiUrl + "/dashboard/student/GetMonthsForFilter", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.months = response.data.data;
        });
    },
    GetUnfinishedSalaries() {
      axios
        .get(this.getApiUrl + "/salaries/get_unfinished_salaries", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.count_unfinished_salaries = response.data.data;
        });
    },
    /*----------------------------
        Fast Statistics
    -----------------------------*/
    getAllStatisttics() {
      // contractNotifcation
      axios
        .get(this.getApiUrl + "/employee/contractNotifcation", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          //Request Leavs
          this.statistticsObject.request_count =
            response.data.data.request_count;
          this.statistticsObject.pending_count =
            response.data.data.pending_count;
          this.statistticsObject.rejectd_count =
            response.data.data.rejectd_count;
          this.statistticsObject.confirmed_count =
            response.data.data.confirmed_count;

          //Contract
          this.statistticsObject.contract_count =
            response.data.data.contract_count;
          this.statistticsObject.contract_valid_count =
            response.data.data.contract_valid_count;
          this.statistticsObject.contract_ending_soon_count =
            response.data.data.contract_ending_soon_count;
          this.statistticsObject.contract_archived =
            response.data.data.contract_archived;
          //probation
          this.statistticsObject.probation_count =
            response.data.data.probation_count;
          this.statistticsObject.probation_onging =
            response.data.data.probation_onging;
          this.statistticsObject.probation_contracted =
            response.data.data.probation_contracted;
          this.statistticsObject.probation_terminated =
            response.data.data.probation_terminated;
          // this.LeaveRequestsCount = response.data.data.leaves_count;
          // this.permissionRequestsCount = response.data.data.permission_count;
        });
    },
  },
  mounted() {
    this.getLeavesStatisticsChart();
    this.getAllStatisttics();
    this.getRequestLeaveCount();
    this.createBarChart();
    this.getLeavesStatisticsFIlterItems();
    this.getYears();
    this.getMonths();
    this.GetUnfinishedSalaries();
    if (localStorage.is_employee == "true") {
      this.is_imployee = true;
    }
  },
};
</script>

<style lang="scss" scoped>
$main-color: #7297ff;
$stats-firstColor: #ff8972;
$stats-secondColor: #7297ff;
$stats-thirdColor: #ffb400;

.main-color {
  color: $main-color !important;
}

.card-title {
  font-size: 1.2rem !important;
}

.employees,
.salaries,
.leave-request,
.leave-stats,
.employee-holidays {
  height: 100% !important;
}
.salaries-total {
  .first-card {
    height: 45%;
  }
  .second-card {
    height: 53%;
  }
}

.statistics {
  .item {
    .v-sheet {
      box-shadow: 0px 20px 80px #00000022 !important;
      position: relative;
      border-radius: 5px;

      .num {
        font-size: 2rem !important;
        color: $stats-firstColor;
      }
      .text {
        font-size: 12px;
      }
    }

    .v-sheet::after {
      content: "arrow_forward";
      font-size: 1rem;
      color: #ffffff;
      font-weight: bold;
      display: flex;
      align-items: center;
      width: 20px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      font-family: "Material Icons";
      border-radius: 5px;
    }
  }

  .item1 {
    .v-sheet {
      .num {
        color: $stats-firstColor;
      }
    }

    .v-sheet::after {
      background-color: $stats-firstColor;
    }
  }
  .item2,
  .item3 {
    .v-sheet {
      .num {
        color: $stats-secondColor;
      }
    }
    .v-sheet::after {
      background-color: $stats-secondColor;
    }
  }

  .item4 {
    .v-sheet {
      .num {
        color: $stats-thirdColor;
      }
    }
    .v-sheet::after {
      background-color: $stats-thirdColor;
    }
  }
}

.chart-info {
  .val {
    font-size: 14px !important;
  }
  .num {
    font-size: 14px !important;
  }
}

.salaries-total {
  .num {
    font-size: 1rem !important;
  }
}

.link {
  text-decoration: none !important;
  color: grey !important;
}

.employee-holidays {
  .avatar {
    border-radius: 50%;
  }
  p,
  span {
    font-size: 12px !important;
  }
}

.salaries {
  .progress-info {
    font-size: 10px;
  }
}
.item1 a.link-open:hover p {
  color: $stats-firstColor !important;
}
.item2 a.link-open:hover p,
.item3 a.link-open:hover p {
  color: $stats-secondColor !important;
}
</style>
